import { combineReducers } from "redux";
import addressReducer from '../redux/address/reducer'
import cartReducer from '../redux/cart/reducer'
import categoryReducer from '../redux/category/reducer'
import commonReducer from '../redux/common/reducer'
import favouriteReducer from '../redux/favourite/reducer'
import homeReducer from '../redux/home/reducer'
import loyaltyReducer from '../redux/loyalty/reducer'
import ordersReducer from '../redux/orders/reducer'
import productsReducer from '../redux/products/reducer'
import promotionReducer from '../redux/promotion/reducer'
import referralReducer from '../redux/referral/reducer'
import settingsReducer from '../redux/settings/reducer'
import userReducer from '../redux/user/reducer'


export default combineReducers({
    address: addressReducer,
    cart: cartReducer,
    category: categoryReducer,
    common: commonReducer,
    favourite: favouriteReducer,
    home: homeReducer,
    loyalty: loyaltyReducer,
    orders: ordersReducer,
    products: productsReducer,
    promotion: promotionReducer,
    referral: referralReducer,
    settings: settingsReducer,
    user: userReducer,
});
