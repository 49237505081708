import React from 'react';
import './assets/css/main.css';
import ReactDOM from 'react-dom/client';
import Root from './Root';
import Loadable from "react-loadable";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import Loading from './components/Mobile/elements/loading';
import GetStarted from './components/Mobile/GetStarted';
import ChooseGender from './components/Mobile/ChooseGender'
import KeepAlive from 'react-activation';
import { withAliveScope } from 'react-activation';
import { AliveScope } from 'react-activation'


const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};
const Profile = Loadable({
    loader: () => import('./components/Mobile/Profile'),
    loading: () => <Loading />,
});
const MyProfile = Loadable({
    loader: () => import('./components/Mobile/Profile/MyProfile'),
    loading: () => <Loading />,
});
const AllCategory = Loadable({
    loader: () => import('./components/Mobile/AllCategory'),
    loading: () => <Loading />,
});
const SingleCategoryHome = Loadable({
    loader: () => import('./components/Mobile/SingleCategoryHome'),
    loading: () => <Loading />,
});
const Favourite = Loadable({
    loader: () => import('./components/Mobile/Favourite'),
    loading: () => <Loading />,
});
const BrandkoWallet = Loadable({
    loader: () => import('./components/Mobile/Profile/BrandkoWallet'),
    loading: () => <Loading />,
});
const MyReward = Loadable({
    loader: () => import('./components/Mobile/Profile/MyReward'),
    loading: () => <Loading />,
});
const Address = Loadable({
    loader: () => import('./components/Mobile/Address'),
    loading: () => <Loading />,
});
const AddAddress = Loadable({
    loader: () => import('./components/Mobile/Address/AddAddress'),
    loading: () => <Loading />,
});
const SingleItem = Loadable({
    loader: () => import('./components/Mobile/SingleItem'),
    loading: () => <Loading />,
});
const HelpCentre = Loadable({
    loader: () => import('./components/Mobile/HelpCentre'),
    loading: () => <Loading />,
});
const ReferAndEarn = Loadable({
    loader: () => import('./components/Mobile/Profile/ReferAndEarn'),
    loading: () => <Loading />,
});
const AccountSettings = Loadable({
    loader: () => import('./components/Mobile/Profile/AccountSettings'),
    loading: () => <Loading />,
});
const Bag = Loadable({
    loader: () => import('./components/Mobile/Bag'),
    loading: () => <Loading />,
});
const Notifications = Loadable({
    loader: () => import('./components/Mobile/Notifications'),
    loading: () => <Loading />,
});
const Search = Loadable({
    loader: () => import('./components/Mobile/Search'),
    loading: () => <Loading />,
});
const MyOrders = Loadable({
    loader: () => import('./components/Mobile/MyOrders'),
    loading: () => <Loading />,
});
const Login = Loadable({
    loader: () => import('./components/Mobile/Login'),
    loading: () => <Loading />,
});
const UserRegister = Loadable({
    loader: () => import('./components/Mobile/Login/UserRegister'),
    loading: () => <Loading />,
});
const SingleCoupon = Loadable({
    loader: () => import('./components/Mobile/SingleCoupon'),
    loading: () => <Loading />,
});
const SingleDeals = Loadable({
    loader: () => import('./components/Mobile/SingleDeals'),
    loading: () => <Loading />,
});
const Guide = Loadable({
    loader: () => import('./components/Mobile/Guide'),
    loading: () => <Loading />,
});
const EditProfile = Loadable({
    loader: () => import('./components/Mobile/Profile/MyProfile/EditProfile'),
    loading: () => <Loading />,
});
const SelectCoupon = Loadable({
    loader: () => import('./components/Mobile/Bag/SelectCoupon'),
    loading: () => <Loading />,
});
const OrderConfirmation = Loadable({
    loader: () => import('./components/Mobile/Bag/OrderConfirmation'),
    loading: () => <Loading />,
});
const OrderSuccess = Loadable({
    loader: () => import('./components/Mobile/Bag/OrderSuccess'),
    loading: () => <Loading />,
});
const OrderFailed = Loadable({
    loader: () => import('./components/Mobile/Bag/OrderFailed'),
    loading: () => <Loading />,
});
const SingleOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/SingleOrder'),
    loading: () => <Loading />,
});
const CancelOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/CancelOrder'),
    loading: () => <Loading />,
});
const CancelReturnOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/CancelReturnOrder'),
    loading: () => <Loading />,
});
const SpinWheel = Loadable({
    loader: () => import('./components/Mobile/SpinWheel'),
    loading: () => <Loading />,
});
const TrackOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/TrackOrder'),
    loading: () => <Loading />,
});
const ReturnOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/ReturnOrder'),
    loading: () => <Loading />,
});
const Prize = Loadable({
    loader: () => import('./components/Mobile/Prize'),
    loading: () => <Loading />,
});
const MileStone = Loadable({
    loader: () => import('./components/Mobile/MileStone'),
    loading: () => <Loading />,
});
const SingleCategory = Loadable({
    loader: () => import('./components/Mobile/SingleCategory'),
    loading: () => <Loading />,
});

const SingleReturnOrder = Loadable({
    loader: () => import('./components/Mobile/MyOrders/SingleReturnOrder'),
    loading: () => <Loading />,
});
const SingleBrand = Loadable({
    loader: () => import('./components/Mobile/SingleBrand'),
    loading: () => <Loading />,
});
const SearchItems = Loadable({
    loader: () => import('./components/Mobile/Home/SearchItems'),
    loading: () => <Loading />,
});

const Home = Loadable({
    loader: () => import('./components/Mobile/Home'),
    loading: () => <Loading />,
});

const CustomerCare = Loadable({
    loader: () => import('./components/Mobile/Profile/CustomerCare'),
    loading: () => <Loading />,
});

const HomeKeepAlive = () => {
    return (
        <KeepAlive>
            <Home />
        </KeepAlive>
    );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Root>
        <BrowserRouter>
            <AliveScope>
                <Route component={ScrollToTop} />
                <Switch>
                    <Route exact path="/" component={withRouter(GetStarted)} />
                    <Route exact path="/choose-gender" component={withRouter(ChooseGender)} />
                    <Route exact path="/profile" component={withRouter(Profile)} />
                    <Route exact path="/my-profile" component={withRouter(MyProfile)} />
                    <Route exact path="/home" component={withAliveScope(HomeKeepAlive)} />
                    <Route exact path="/login" component={withRouter(Login)} />
                    <Route exact path="/user-register" component={withRouter(UserRegister)} />
                    <Route exact path="/category" component={withRouter(AllCategory)} />
                    <Route exact path="/favourite" component={withRouter(Favourite)} />
                    <Route exact path="/my-orders" component={withRouter(MyOrders)} />
                    <Route exact path="/single-category-home/:id" component={withRouter(SingleCategoryHome)} />
                    <Route exact path="/brandko-wallet" component={withRouter(BrandkoWallet)} />
                    <Route exact path="/reward" component={withRouter(MyReward)} />
                    <Route exact path="/address" component={withRouter(Address)} />
                    <Route exact path="/add-address/:id" component={withRouter(AddAddress)} />
                    <Route exact path="/single-item/:id" component={withRouter(SingleItem)} />
                    <Route exact path="/single-coupon/:id" component={withRouter(SingleCoupon)} />
                    <Route exact path="/single-deals/:type/:id" component={withRouter(SingleDeals)} />
                    <Route exact path="/notifications" component={withRouter(Notifications)} />
                    <Route exact path="/help-centre" component={withRouter(HelpCentre)} />
                    <Route exact path="/refer-and-earn" component={withRouter(ReferAndEarn)} />
                    <Route exact path="/account-settings" component={withRouter(AccountSettings)} />
                    <Route exact path="/Bag" component={withRouter(Bag)} />
                    <Route exact path="/search" component={withRouter(Search)} />
                    <Route exact path="/size-chart/:id" component={withRouter(Guide)} />
                    <Route exact path="/edit-profile" component={withRouter(EditProfile)} />
                    <Route exact path="/select-coupon" component={withRouter(SelectCoupon)} />
                    <Route exact path="/order-confirmation" component={withRouter(OrderConfirmation)} />
                    <Route exact path="/order-success/:id" component={withRouter(OrderSuccess)} />
                    <Route exact path="/order-failed/:id" component={withRouter(OrderFailed)} />
                    <Route exact path="/single-order/:id" component={withRouter(SingleOrder)} />
                    <Route exact path="/single-brand/:id" component={withRouter(SingleBrand)} />
                    {/* <Route exact path="/single-order" component={withRouter(SingleOrder)} /> */}
                    <Route exact path="/spin-wheel" component={withRouter(SpinWheel)} />
                    <Route exact path="/track-order/:id" component={withRouter(TrackOrder)} />
                    <Route exact path="/cancel-order/:id" component={withRouter(CancelOrder)} />
                    <Route exact path="/cancel-return-order/:id" component={withRouter(CancelReturnOrder)} />
                    <Route exact path="/return-order/:id" component={withRouter(ReturnOrder)} />
                    <Route exact path="/prize" component={withRouter(Prize)} />
                    <Route exact path="/milestones" component={withRouter(MileStone)} />
                    <Route exact path="/single-category/:id" component={withRouter(SingleCategory)} />
                    <Route exact path="/single-return-order/:id" component={withRouter(SingleReturnOrder)} />
                    <Route exact path="/search-items" component={withRouter(SearchItems)} />
                    <Route exact path="/customer-care" component={withRouter(CustomerCare)} />
                </Switch>
            </AliveScope>
        </BrowserRouter>
    </Root>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
