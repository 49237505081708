/* eslint-disable import/no-anonymous-default-export */

import {  GET_ALL_HOME_PAGE_DATA,GET_LAST_VIEWED_PRODUCTS } from "./actionType";

const initialState = {
    homePage: [],
    lastViewedProducts:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_HOME_PAGE_DATA:
            return { ...state, homePage: action.payload };

        case GET_LAST_VIEWED_PRODUCTS:
            return {...state,lastViewedProducts:action.payload}

        default:
            return state;
    }
}
