/* eslint-disable import/no-anonymous-default-export */

import { GET_USER_ADDRESS} from "./actionType";


const initialState = {
    address: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        // case SEND_OTP:
        //     return { ...state, address: action.payload };
        case GET_USER_ADDRESS:
            return { ...state, address: action.payload };

        default:
            return state;
    }
}
