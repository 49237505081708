/* eslint-disable import/no-anonymous-default-export */

import {
    GET_FAVOURITE_PRODUCTS
} from "./actionType";

const initialState = {
    favourite: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FAVOURITE_PRODUCTS:
            return { ...state, favourite: action.payload };
        // case SEND_OTP:
        //     return { ...state, favourite: action.payload };

        default:
            return state;
    }
}
