/* eslint-disable import/no-anonymous-default-export */

import {
    LOGIN_OTP_USER,
    LOGOUT_USER,
    GET_VENDOR_DETAILS,
    REGISTER_USER,
    ADD_VENDOR_DETAILS_IMAGE,
    RESTORE_USER,
    SEND_OTP,
    UPDATE_USER_REDUX_INFO,
    GET_WALLET_AMOUNT,
    DELETE_USER
} from "./actionType";


const initialState = {
    user: [],
    wallet: []
};

export default function (state = initialState, action) {
    switch (action.type) {

        case UPDATE_USER_REDUX_INFO:
            return { ...state, user: action.payload };
        case SEND_OTP:
            return { ...state, user: action.payload };
        case ADD_VENDOR_DETAILS_IMAGE:
            return { ...state, user: action.payload };
        case GET_VENDOR_DETAILS:
            return { ...state, user: action.payload };
        case REGISTER_USER:
            return { ...state, user: action.payload };
        case LOGIN_OTP_USER:
            return { ...state, user: action.payload };
        case DELETE_USER:
            return { ...state, user: action.payload };
        case RESTORE_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case GET_WALLET_AMOUNT:
            return { ...state, wallet: action.payload }
        default:
            return state;
    }
}
