import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState) => {
    initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;
    const middleware = [thunk];

    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(...middleware)
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    );

    store.subscribe(() => {
        const state = store.getState();
        //save the config to localstorage
        // for (const setting in state.settings.settings) {
        // 	if (state.settings.settings.hasOwnProperty(setting)) {
        // 		const element = state.settings.settings[setting];
        // 		localStorage.setItem(element.key, element.value);
        // 	}
        // }

        // for (const translation in state.languages.language) {
        // 	if (state.languages.language.hasOwnProperty(translation)) {
        // 		const trans = state.languages.language[translation];
        // 		localStorage.setItem(translation, trans);
        // 	}
        // }

        const persist = {
            user: state.user,
            category: state.category,
            orders: state.orders,
            common: state.common,
            address: state.address,
            loyalty: state.loyalty,
            products: state.products,
            cart: state.cart,
            home: state.home,
            promotion: state.promotion,
            settings: state.settings,
            favourite: state.favourite,

        };
        localStorage.setItem("state", JSON.stringify(persist));

        if (window.ReactNativeWebView) {
            const response = {
                type: 'state',
                data: persist
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(response));
        }
        // const user = {
        //     user: state.user
        // };
        // window.localStorage.setItem("user", JSON.stringify(user.user));
    });

    return store;
};
