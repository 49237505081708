import { ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_CART,GET_DELIVERY_CHARGE ,GET_ALL_COUPONS,APPLY_COUPON,SAVE_DELIVERY_CHARGE,PLACE_ORDER} from "./actionType";

const initialState = {
    products: [],
    total: [],
    delivery_charges: [],
    coupons:[],
    checkout:[],
    appliedCoupon:[],
    charge: 0

};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                productToAdd: Object.assign({}, action.payload),
            };
        case REMOVE_PRODUCT:
            return {
                ...state,
                productToRemove: Object.assign({}, action.payload),
            };
        case UPDATE_CART:
            return {
                ...state,
                total: action.payload,
            };
        case GET_DELIVERY_CHARGE:
            return {
                ...state,
                delivery_charges: action.payload,
            };
        case GET_ALL_COUPONS:
            return {
                ...state,
                coupons: action.payload,
            };
        case APPLY_COUPON:
            return {
                ...state,
                appliedCoupon: action.payload,
            };
        case SAVE_DELIVERY_CHARGE:
            return {
                ...state,
                charge: action.payload,
            };
        case PLACE_ORDER:
            return {
                ...state,
                checkout: action.payload,
            };

        default:
            return state;
    }
}
