export const UPDATE_USER_REDUX_INFO = "UPDATE_USER_REDUX_INFO";
export const SEND_OTP = "SEND_OTP";
export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const REGISTER_USER = "REGISTER_USER";
export const ADD_VENDOR_DETAILS_IMAGE = "ADD_VENDOR_DETAILS_IMAGE";
export const ADD_USER_IDENTITY_PROOF = "ADD_USER_IDENTITY_PROOF";
export const ADD_PROOF_DETAILS_IMAGE = "ADD_PROOF_DETAILS_IMAGE";
export const LOGIN_OTP_USER = "LOGIN_OTP_USER";

export const LOGOUT_USER = "LOGOUT_USER";
export const DELETE_USER = "DELETE_USER";
export const RESTORE_USER = "RESTORE_USER";

export const ADD_USER_PROFILE = 'ADD_USER_PROFILE';
export const UPDATE_USER_DETAILS_URL = "UPDATE_USER_DETAILS_URL";

export const GET_WALLET_AMOUNT = "GET_WALLET_AMOUNT";

