/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_ORDERS,
    GET_SINGLE_ORDERS,
    GET_SINGLE_RETURN_ORDER
} from "./actionType";

const initialState = {
    orders: [],
    singleOrders: [],
    cancelOrder: [],
    singleReturnOrder:{}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ORDERS:
            return { ...state, orders: action.payload };
        case GET_SINGLE_ORDERS:
            return { ...state, singleOrders: action.payload };
        case GET_SINGLE_RETURN_ORDER:
            return { ...state, singleReturnOrder: action.payload };
        // case SEND_OTP:
        //     return { ...state, orders: action.payload };

        default:
            return state;
    }
}
