/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_MAIN_CATEGORY, GET_SINGLE_CATEGORY_PRODUCT,
    // GET_ALL_SINGLE_MAIN_CATEGORY,
    GET_SINGLE_CATEGORY,
    GET_ITEMS_FOR_FILTER
} from './actionType';

const initialState = {
    categories: [],
    singleCategory: [],
    categoryProduct: [],
    // single_category: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MAIN_CATEGORY:
            return { ...state, categories: action.payload };
        case GET_SINGLE_CATEGORY:
            return { ...state, singleCategory: action.payload };
        case GET_SINGLE_CATEGORY_PRODUCT:
            return { ...state, categoryProduct: action.payload };
        case GET_ITEMS_FOR_FILTER:
                return { ...state, filterProduct: action.payload };    
        // case GET_ALL_SINGLE_MAIN_CATEGORY:
        //     return { ...state, single_category: action.payload };
        default:
            return state;
    }
}
