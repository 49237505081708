/* eslint-disable import/no-anonymous-default-export */
import { GET_SETTING_KEYS } from './actionType';


const initialState = {
    settings: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_SETTING_KEYS:
            return { ...state, settings: action.payload };

        default:
            return state;
    }
}
