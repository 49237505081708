import React, { Component } from 'react'
import { connect } from 'react-redux'
import Model1 from '../../../assets/images/roufan.png';
import Model2 from '../../../assets/images/modal-2.png';
import Model3 from '../../../assets/images/model-3.png';
import { VscDebugStackframeDot } from 'react-icons/vsc';
import { GoPrimitiveDot } from 'react-icons/go';
import { Link, Redirect } from 'react-router-dom';
import Ink from 'react-ink';

export class GetStarted extends Component {
    state = {
        loading: false,
        splash1: true,
        splash2: false,
        splash3: false,
    }
    componentDidMount() {
        if (window.innerWidth <= 1024) {
            if (localStorage.getItem("loggedIn")) {
                window.location.replace("/home")
            } else {
                window.location.replace("/login")
            }
        } else {
            window.location.replace('https://brandko.howincloud.com/landing/')
        }
    }
    toggleSplash2 = () => {
        this.setState({ splash1: false, splash2: true, })
    }
    toggleSplash3 = () => {
        // this.state.loading
        this.setState({ splash1: false, splash2: false, splash3: true })
    }
    goToGender = () => {
        this.setState({ loading: true, splash1: false, splash2: false, splash3: false })
        window.location.replace("/login");
        localStorage.setItem("SPLASH", true);
    }
    render() {
        if (window.innerWidth <= 1024) {
            if (localStorage.getItem("loggedIn")) {
                window.location.replace("/home")
            } else {
                window.location.replace("/login")
            }
        } else {
            window.location.replace('https://brandko.howincloud.com/landing/')
        }
        return (
            <React.Fragment>

                {this.state.loading &&
                    <React.Fragment>
                        {this.state.splash1 &&
                            <div className='d-flex flex-column h-100 ' style={{ paddingTop: '20vw' }}>
                                <div>
                                    <div className='d-flex flex-row justify-content-around align-items-center'>
                                        <div className=' '>
                                            <span className='text-90d ' style={{ fontSize: '2.5em', fontWeight: '800', }} >
                                                Brandko.
                                            </span>
                                        </div>
                                        <div className=''>
                                            <div className='position-relative'
                                                style={{
                                                    backgroundColor: '#EFEDED', color: '#EFEDED', height: '70vw',
                                                    width: '70vw',
                                                    borderRadius: '50% ',
                                                    zIndex: '-999',
                                                    // transform: 'translate(20%, 10%)',
                                                }}
                                            >

                                                <img className='' src={Model1} alt='brandko' style={{ objectFit: 'cover', height: '80vw', objectPosition: 'top center', }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-2' style={{ borderTop: '40px solid #000' }}>
                                </div>
                                <div className='px-4 pt-4 d-flex flex-column bg-white'>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Let your
                                    </span>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Style Speak
                                    </span>
                                </div>
                                <div className='text-muted px-4 pt-4 w-75' style={{ fontSize: '1.2em' }}>
                                    Discover the latest trends in mens
                                    fashion and explore your personality
                                </div>
                                <div className='pt-4 px-4'>
                                    <button className='btn btn-dark position-relative py-3 b-r-15' onClick={() => this.toggleSplash2()}>
                                        <span className='px-5 fs-5 ' style={{ fontWeight: '500' }}>Get Started</span>
                                        <Ink />
                                    </button>
                                </div>
                                <div className='mt-3 position-fixed start-50 translate-middle-x' style={{ bottom: '35px' }}>
                                    <div className="d-flex flex-row justify-content-center align-items-center  mt-3  w-100">
                                        {/* <div className="b-r-50 p-2" style={{ backgroundColor: '#035499', }}></div> */}
                                        <GoPrimitiveDot className="active-dot ms-1" />
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.splash2 &&
                            <div className='d-flex flex-column h-100 ' style={{ paddingTop: '20vw' }}>
                                <div>
                                    <div className='d-flex flex-row justify-content-around align-items-center'>
                                        <div className=' '>
                                            <span className='text-90d ' style={{ fontSize: '2.5em', fontWeight: '800', }} >
                                                Brandko.
                                            </span>
                                        </div>
                                        <div className=''>
                                            <div className='position-relative'
                                                style={{
                                                    backgroundColor: '#EFEDED', color: '#EFEDED', height: '70vw',
                                                    width: '70vw',
                                                    borderRadius: '50% ',
                                                    zIndex: '-999',
                                                    // transform: 'translate(20%, 10%)',
                                                }}
                                            >

                                                <img className='' src={Model2} alt='brandko' style={{ objectFit: 'cover', height: '80vw', objectPosition: 'top center', }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-2' style={{ borderTop: '40px solid #000' }}>
                                </div>
                                <div className='px-4 pt-4 d-flex flex-column bg-white'>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Let your
                                    </span>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Style Speak
                                    </span>
                                </div>
                                <div className='text-muted px-4 pt-4 w-75' style={{ fontSize: '1.2em' }}>
                                    Discover the latest trends in mens
                                    fashion and explore your personality
                                </div>
                                <div className='pt-4 px-4'>
                                    <button className='btn btn-dark position-relative py-3 b-r-15' onClick={() => this.toggleSplash3()}>
                                        <span className='px-5 fs-5 ' style={{ fontWeight: '500' }}>Get Started</span>
                                        <Ink />
                                    </button>
                                </div>
                                <div className='mt-3 position-fixed start-50 translate-middle-x' style={{ bottom: '35px' }}>
                                    <div className="d-flex flex-row justify-content-center align-items-center  mt-3  w-100">
                                        {/* <div className="b-r-50 p-2" style={{ backgroundColor: '#035499', }}></div> */}
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                        <GoPrimitiveDot className="active-dot ms-1" />
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.splash3 &&
                            <div className='d-flex flex-column h-100 ' style={{ paddingTop: '20vw' }}>
                                <div>
                                    <div className='d-flex flex-row justify-content-around align-items-center'>
                                        <div className=' '>
                                            <span className='text-90d ' style={{ fontSize: '2.5em', fontWeight: '800', }} >
                                                Brandko.
                                            </span>
                                        </div>
                                        <div className=''>
                                            <div className='position-relative'
                                                style={{
                                                    backgroundColor: '#EFEDED', color: '#EFEDED', height: '70vw',
                                                    width: '70vw',
                                                    borderRadius: '50% ',
                                                    zIndex: '-999',
                                                    // transform: 'translate(20%, 10%)',
                                                }}
                                            >

                                                <img className='' src={Model3} alt='brandko' style={{ objectFit: 'cover', height: '80vw', objectPosition: 'top center', }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-2' style={{ borderTop: '40px solid #000' }}>
                                </div>
                                <div className='px-4 pt-4 d-flex flex-column bg-white'>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Let your
                                    </span>
                                    <span className='fw-bolder' style={{ fontSize: '3em', lineHeight: 'normal' }}>
                                        Style Speak
                                    </span>
                                </div>
                                <div className='text-muted px-4 pt-4 w-75' style={{ fontSize: '1.2em' }}>
                                    Discover the latest trends in mens
                                    fashion and explore your personality
                                </div>
                                <div className='pt-4 px-4'>
                                    {/* <Link to="/choose-gender"> */}
                                    <button className='btn btn-dark position-relative py-3 b-r-15' onClick={() => this.goToGender()}>
                                        <span className='px-5 fs-5 ' style={{ fontWeight: '500' }}>Get Started</span>
                                        <Ink />
                                    </button>
                                    {/* </Link> */}
                                </div>
                                <div className='mt-3 position-fixed start-50 translate-middle-x' style={{ bottom: '35px' }}>
                                    <div className="d-flex flex-row justify-content-center align-items-center  mt-3  w-100">
                                        {/* <div className="b-r-50 p-2" style={{ backgroundColor: '#035499', }}></div> */}
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                        <VscDebugStackframeDot className="inactive-dot ms-1" />
                                        <GoPrimitiveDot className="active-dot ms-1" />
                                    </div>
                                </div>
                            </div>
                        }

                    </React.Fragment >
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})
export default connect(mapStateToProps, {

})(GetStarted)
