import React, { Component } from 'react';
import { BiChevronLeftCircle } from 'react-icons/bi';
import Ink from 'react-ink';
import Lottie from 'react-lottie';
import loading from '../../../assets/animation/loading.json'
import { withRouter } from 'react-router-dom';
class Loading extends Component {
    render() {
        const loadingOptions = {
            loop: true,
            autoplay: true,
            animationData: loading,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <React.Fragment>
                <div className='header-fixed ps-3 pt-3 '>
                    <div className='back-button-loading d-flex justify-content-center align-items-center py-2 px-2' onClick={this.props.history.goBack}>
                        <Ink className="touch-ink" />
                        <BiChevronLeftCircle color='#fff' size={'2em'} />
                    </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center align-content-center  overlay-loading ' style={{ height:'80vh' }}>
                    <Lottie options={loadingOptions}
                        height={300}
                        width={300}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Loading);
