import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export class ChooseGender extends Component {
    render() {
        const { user } = this.props;
        // if (user.success || localStorage.getItem("SPLASH")) {
        //     window.location.replace("/login")
        // }
        return (
            <React.Fragment>
                <div className='position-absolute choose-gender-background w-100 h-100 '>
                    <span className='brandko-90d position-absolute' style={{
                        fontSize: '2.5em', fontWeight: '800', marginTop: '50vw', right: '5px'
                    }} >Brandko.</span>
                    <div className='d-flex flex-column position-absolute w-100' style={{ bottom: '20vw' }}>
                        <span className=' text-white text-start ms-4 px-5' style={{
                            fontSize: '2em', fontWeight: '800'
                        }} >Make your</span>
                        <span className='text-white text-end me-4 px-5' style={{
                            fontSize: '2em', fontWeight: '800'
                        }} >shopping as</span>
                        <hr className='mt-2' style={{ width: '70%', height: '5px', margin: 'auto', borderTop: '2px solid #fff' }} />
                        <div className='d-flex flex-row px-5 justify-content-around mt-3'>
                            <Link to="/login">
                                <button type='button' className='btn light-button-effect fw-bolder' style={{ borderRadius: '15px', padding: '10px', width: '35vw' }}>
                                    Men
                                </button>
                            </Link>
                            <Link to="/login">
                                <button type='button' className='btn light-button-effect fw-bolder' style={{ borderRadius: '15px', padding: '10px', width: '35vw' }}>
                                    Women
                                </button>
                            </Link>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})


export default connect(mapStateToProps, {

})(ChooseGender)
