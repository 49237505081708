/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_MILESTONES
} from "./actionType";

const initialState = {
    milestones: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MILESTONES:
            return { ...state, milestones: action.payload };

        default:
            return state;
    }
}
