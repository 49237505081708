/* eslint-disable import/no-anonymous-default-export */

import { CHECK_REFERRAL } from "./actionType";


const initialState = {
    referral: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        case CHECK_REFERRAL:
            return { ...state, referral: action.payload };

        default:
            return state;
    }
}
