/* eslint-disable import/no-anonymous-default-export */

import {
    GET_SINGLE_DEALS,
    GET_SINGLE_COUPON
} from "./actionType";

const initialState = {
    promotion: [],
    singleDeals: [],
    singleCoupon: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_DEALS:
            return { ...state, singleDeals: action.payload };
        case GET_SINGLE_COUPON:
            return { ...state, singleCoupon: action.payload };
        default:
            return state;
    }
}
