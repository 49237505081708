
import {
    GET_FILTERED_PRODUCT,
    GET_LAST_VIEWED_PRODUCTS,
    // TOGGLE_FAVOURITE_ITEM
    GET_SINGLE_PRODUCT,
    GET_TOP_BRAND,
} from "./actionType";

const initialState = {
    lastViewedProduct: [],
    singleProduct: [],
    singleBrand: [],

};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LAST_VIEWED_PRODUCTS:
            return { ...state, lastViewedProduct: action.payload };
        case GET_SINGLE_PRODUCT:
            return { ...state, singleProduct: action.payload };
        // case TOGGLE_FAVOURITE_ITEM:
        //     return { ...state, varient: action.payload };
        case GET_FILTERED_PRODUCT:
            return { ...state, varient: action.payload };
        case GET_TOP_BRAND:
            return { ...state, singleBrand: action.payload };
        default:
            return state;
    }
}
