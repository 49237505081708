/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_NOTIFICATIONS,
    GET_SPINNER_DATA,
    GET_HELP
    // GET_ALL_SLIDERS,
} from "./actionType";

const initialState = {
    // sliders: [],
    notifications: [],
    rewards: [],
    helps: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_NOTIFICATIONS:
            return { ...state, notifications: action.payload };
        case GET_SPINNER_DATA:
            return { ...state, rewards: action.payload };
        case GET_HELP:
            // console.log(action.payload,'hduhudyguydudy');
            return { ...state, helps: action.payload };
        default:
            return state;
    }
}

/* eslint-disable import/no-anonymous-default-export */
